import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "nav-container" }

import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { WbTabs, WbTab } from '@workbench/vue'
import { NavItem } from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'Nav',
  props: {
    navConfig: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()
const route = useRoute()

const selectedTab = computed(() => {
  const currentTab = props.navConfig.find(
    (tab) => tab.name === route.path.split('/')[1]
  )
  return currentTab?.name || props.navConfig[0].name
})

const handleTabClick = (tabName: string) => {
  const routeArr = route.path.split('/')
  if (tabName === routeArr[1] && routeArr.length === 2) return
  router.push(`/${tabName}`)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(WbTabs), {
      selected: selectedTab.value,
      theme: "dark"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navConfig, (tab) => {
          return (_openBlock(), _createBlock(_unref(WbTab), {
            key: tab.name,
            name: tab.name,
            class: "nav-item",
            onClick: () => handleTabClick(tab.name)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tab.label), 1)
            ]),
            _: 2
          }, 1032, ["name", "onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["selected"])
  ]))
}
}

})