import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue"

import { computed } from 'vue'
import { useStore } from 'vuex'
import { WbNotificationHost, WbNotification, WbButton } from '@workbench/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalNotification',
  setup(__props) {

const store = useStore()

const visibility = computed(() => store.state.notificationVisibility)
const message = computed(() => store.state.notificationMessage)
const variant = computed(() => store.state.notificationVariant)
const actionName = computed(() => store.state.notificationActionName)
const action = computed(() => store.state.notificationAction)

const position = computed(() =>
  variant.value === 'success' ? 'bottom-end' : 'top-center'
)

return (_ctx: any,_cache: any) => {
  return (visibility.value)
    ? (_openBlock(), _createBlock(_unref(WbNotificationHost), {
        key: 0,
        position: position.value
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(WbNotification), {
            variant: variant.value,
            "hide-dismiss-button": "",
            style: _normalizeStyle(position.value !== 'bottom-end' ? 'margin-top:120px;' : '')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(message.value) + " ", 1),
                (actionName.value)
                  ? (_openBlock(), _createBlock(_unref(WbButton), {
                      key: 0,
                      variant: "tertiary",
                      size: "s",
                      onClick: action.value,
                      slot: "footer"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(actionName.value), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["variant", "style"])
        ]),
        _: 1
      }, 8, ["position"]))
    : _createCommentVNode("", true)
}
}

})