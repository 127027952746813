<template>
  <wb-notification-host :position="position" v-if="visibility">
    <wb-notification
      :variant="variant"
      hide-dismiss-button
      :style="position !== 'bottom-end' ? 'margin-top:120px;' : ''"
    >
      <div>
        {{ message }}
        <wb-button
          v-if="actionName"
          variant="tertiary"
          size="s"
          @click="action"
          slot="footer"
        >
          {{ actionName }}
        </wb-button>
      </div>
    </wb-notification>
  </wb-notification-host>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { WbNotificationHost, WbNotification, WbButton } from '@workbench/vue'

const store = useStore()

const visibility = computed(() => store.state.notificationVisibility)
const message = computed(() => store.state.notificationMessage)
const variant = computed(() => store.state.notificationVariant)
const actionName = computed(() => store.state.notificationActionName)
const action = computed(() => store.state.notificationAction)

const position = computed(() =>
  variant.value === 'success' ? 'bottom-end' : 'top-center'
)
</script>
