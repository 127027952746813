import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }

import { onMounted } from 'vue'
import { useStore } from 'vuex'
import Nav from '@/components/layout/Nav.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import { navConfig } from '@/router'
import Footer from '@/components/layout/Footer.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore()

onMounted(() => {
  store.dispatch('fetchDataDictionary')
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Nav, { navConfig: _unref(navConfig) }, null, 8, ["navConfig"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(GlobalNotification),
    _createVNode(Footer)
  ]))
}
}

})