import axios from 'axios'
import store from '@/store'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: { 'Content-Type': 'application/json' }
})

request.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      const currentUrl = window.location.hash
      window.location.href = `/api/login?location=${currentUrl}`
      return Promise.reject(error)
    }
    if (error.response?.status === 403) {
      window.location.href = '/'
      return Promise.reject(error)
    }
    if (error.response?.status === 500) {
      store.dispatch('showNotification', {
        message: 'Server error, please retry.',
        variant: 'error'
      })
      return Promise.reject(error)
    }
    if (error.response?.status === 503) {
      store.dispatch('showNotification', {
        message: 'Service unavailable, please retry.',
        variant: 'error'
      })
      return Promise.reject(error)
    }
    if (error.response.config.ignoreGlobalErrorHandle) {
      return Promise.reject(error)
    }
    if (error.response?.status === 504) {
      store.dispatch('showNotification', {
        message: 'Request timeout, please retry.',
        variant: 'error'
      })
      return Promise.reject(error)
    }
    if (is4XX(error.response?.status)) {
      store.dispatch('showNotification', {
        message: 'System error.',
        variant: 'error'
      })
      return Promise.reject(error)
    }
    store.dispatch('showNotification', {
      message: 'Invalid request, please retry.',
      variant: 'error'
    })
    return Promise.reject(error)
  }
)

const is4XX = (status: number) => {
  return Math.floor(status / 100) === 4
}
export default request
