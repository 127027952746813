import request from '@/api/request'

export const getUserInfo = () => {
  if (process.env.VUE_APP_ENV === 'local') {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            username: 'string',
            department: 'string',
            email: 'thoughworks.zhang@mercedes-benz.com',
            roles: ['ADMIN']
            // roles: ['TASK_OWNER']
          }
        })
      }, 1000)
    })
  }
  return request.get('/oauth/user_info')
}
