import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css

const app = createApp(App).use(store).use(router)

app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('wb-')
app.mount('#app')
