<template>
  <div class="nav-container">
    <wb-tabs :selected="selectedTab" theme="dark">
      <wb-tab
        v-for="tab in navConfig"
        :key="tab.name"
        :name="tab.name"
        class="nav-item"
        @click="() => handleTabClick(tab.name)"
      >
        {{ tab.label }}
      </wb-tab>
    </wb-tabs>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { WbTabs, WbTab } from '@workbench/vue'
import { NavItem } from '@/router'

const props = defineProps<{
  navConfig: NavItem[]
}>()

const router = useRouter()
const route = useRoute()

const selectedTab = computed(() => {
  const currentTab = props.navConfig.find(
    (tab) => tab.name === route.path.split('/')[1]
  )
  return currentTab?.name || props.navConfig[0].name
})

const handleTabClick = (tabName: string) => {
  const routeArr = route.path.split('/')
  if (tabName === routeArr[1] && routeArr.length === 2) return
  router.push(`/${tabName}`)
}
</script>

<style lang="scss" scoped>
.nav-container {
  padding: 1px 24px 0 24px;
  background: var(--wb-black, #000);
  .nav-item {
    border: none;
  }
}
</style>
