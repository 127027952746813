<template>
  <div class="container">
    <Nav :navConfig="navConfig" />
    <div class="content">
      <router-view />
    </div>
    <GlobalNotification />
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import Nav from '@/components/layout/Nav.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import { navConfig } from '@/router'
import Footer from '@/components/layout/Footer.vue'

const store = useStore()

onMounted(() => {
  store.dispatch('fetchDataDictionary')
})
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .content {
    flex: 1;
  }
}
</style>
