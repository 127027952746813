export const ROLES = {
  ADMIN: 'ADMIN',
  TASK_OWNER: 'TASK_OWNER'
}

export const FilingRoute = {
  FILING_CREATE: 'FilingCreate',
  FILING_LIST: 'FilingList',
  FILING_DETAIL: 'FilingDetail'
}

export const TagMapping = {
  COMPLETED: { background: '#198025' },
  SUBMITTED: { background: '#198025' },
  IN_PROGRESS: { background: '#FACC00' },
  NOT_STARTED: { background: '#D92121' }
}
