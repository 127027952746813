<template>
  <div class="footer-container">
    <span class="icp">京ICP备14036751号-4</span>
  </div>
</template>

<style scoped lang="scss">
.footer-container {
  padding: 10px 110px;
  background: var(--wb-black, #000);
}

.icp {
  color: #848484;
  font-size: 14px;
}
</style>
